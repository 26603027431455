import React, { Component, Fragment } from 'react';
// import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha";
const axios = require('axios');
const jwt = require('njwt')

class Politica extends Component {
  state = {
    politicas: [],
    loading: true,
    show: false,
    nome: "",
    email: "",
    anonimo: false,
    arquivo: "",
    required: true,
    disabled: false
  }

  async componentDidMount() {
    try {
      const claims = {}
      const token = jwt.create(claims, '09DSA09DSA')
      axios.get('https://sgo4.redeclaretiano.edu.br/api/v1/policies/data?domain=' + window.location.hostname.split('.').slice(-3).join('.'), { headers: { 'Authorization': token }})
      .then(response => {
        this.setState({ loading: false });
        this.setState({ politicas: response.data });
      })
      .catch(error => {
        console.log(error);
      });


    } catch (error) {
      console.log(error);
    }
    // );
    setTimeout(function() {
      if (window.location.hash == '#contato') {
        document.getElementById('contatos-a').click()
      }

    }, 1000);
  }


  render() {
    const { politicas,loading, nome, email, anonimo, arquivo, descricao, required, disabled } = this.state;
    const recaptchaRef = React.createRef();

    const handleInputNome = event => {
      this.setState({
        nome: event.target.value
      });
    }

    const handleInputEmail = event => {
      this.setState({
        email: event.target.value
      });
    }

    const isAnonimo = event => {
      this.setState({
        anonimo: event.target.checked ? 'none' : 'flex',
        nome: '',
        email: '',
        required: !event.target.checked
      });
    }

    const handleInpuDescricao = event => {
      this.setState({
        descricao: event.target.value
      });
    }

    const handleInputFile = event => {
      this.setState({
        arquivo: event.target.files[0]
      });
      console.log(event.target.files[0]);
    }

    const handleSubmit = event => {
      event.preventDefault();
      const recaptchaValue = recaptchaRef.current.getValue();
      if (recaptchaValue === "") {
        toast.error('Valide o captcha', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return true;
      }
      const that = this;
      const claims = {}
      const token = jwt.create(claims, '09DSA09DSA')
      const formData = new FormData();
      formData.append('nome', nome);
      formData.append('email', email);
      formData.append('descricao', descricao);
      formData.append('arquivo', arquivo);

      this.setState({
        disabled: true
      });

      axios({
        method: "post",
        url: "https://sgo4.redeclaretiano.edu.br/api/v1/policies/contact",
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': token },
      })
        .then(function (response) {
            toast.success('Contato enviado!!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          that.setState({
            disabled: false,
            nome: '',
            email: '',
            descricao: ''
          });
        })
        .catch(function (response) {
          toast.error('Ocorreu um erro , tente novamente mais tarde!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          that.setState({
            disabled: false
          });
        });
    }



    if(loading) { // if your component doesn't have to wait for an async action, remove this block
      return (

        <div className="center-div">
          <div className="loadingio-spinner-spinner-gqef9kq064">
            <div className="ldio-h8jpr8mn9cr">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
             </div>
          </div>
        </div>
      ); // render null when app is not ready
    }
    return (
        <Fragment>
          <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" async defer></script>
          </Helmet>
          <div className="header">
            <h1 className="header-font">Privacidade - {politicas.name}</h1>
            <img src={politicas.logo} className="logo" alt="logo" />
          </div>
          <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-2">
              <div id="sidebar" className="sidebar" >
                <div className="nav margin" id="v-pills-tab" role="tablist">
                  <a className="link-style nav-link" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                    <div>Política de Privacidade</div>
                  </a>

                  <a className="link-style nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                    <div>Política de Cookies</div>
                  </a>

                  <a className="link-style nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#contatos" role="tab" aria-controls="v-pills-profile" aria-selected="false" id="contatos-a">
                    <div>Contato</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              <div className="tab-content container" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div dangerouslySetInnerHTML={createMarkup(politicas.privacy)} />
                </div>
                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <div dangerouslySetInnerHTML={createMarkup(politicas.cookies)} />
                </div>
                <div className="tab-pane fade" id="contatos" role="tabpanel" aria-labelledby="contatos">
                  <h1 className="jumbotron-heading">Contato</h1>
                  <ToastContainer />
                  <form onSubmit={handleSubmit}>
                    <div class="alert alert-warning">
                      Em caso de dúvidas e/ou solicitações relativas ao tratamento de dados, recomenda-se enviar e-mail para o <strong>Encarregado de Dados – DPO (Data Protection Officer)</strong> da AÇÃO EDUCACIONAL CLARETIANA - EDUCLAR: <strong>Fabiano Procópio de Freitas</strong>, cujo endereço é: <a href='mailto:privacidade@claretiano.edu.br'>privacidade@claretiano.edu.br</a> ou preencha o formulário abaixo.
                    </div>
                    <div className="form-group">
                      <label>Anônimo</label>
                      <p><input type="checkbox" onClick={isAnonimo} /></p>
                    </div>
                    <div className="row" style={{display: anonimo}}>
                      <div className="col-sm-6">
                        <label>Nome</label>
                        <input type="text" id="nome" className="form-control" onChange={handleInputNome} value={nome} required={required} />
                      </div>
                      <div className="col-sm-6">
                        <label>E-mail</label>
                        <input type="email" id="email" className="form-control" onChange={handleInputEmail}  value={email} required={required} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Descrição</label>
                      <textarea className="form-control" rows="4" onChange={handleInpuDescricao} value={descricao} required={true} />
                    </div>
                    <div className="form-group">
                      <label>Anexo</label>
                      <input type="file" name="anexo" className="form-control-file" onChange={handleInputFile} />
                    </div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LcEP68mAAAAAM7owZcfobgbvrp57vjYddsHmo3x"
                    />
                    <div className="row">
                      <div className="col-sm-6"><button type="submit" className= "btn btn-primary" disabled={disabled} style={{marginTop: '11px'}}>Enviar</button></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
  }
}

function createMarkup(html) {
  return {__html: html};
}
export default Politica;
