import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Politica from './components/Politica'

export default function Routes() {
  return (
    <Router>
      <Route path='/' component={Politica} />
    </Router>
  );
}